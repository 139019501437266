import { handleAccountCreation, checkAuthUserFirstNameAndLastName } from '../helpers/routes';

export default [
  {
    path: '/otp-links/:password',
    name: 'OtpLinks',
    component: () => import('@/views/MobileScan/OtpLinks.vue'),
  },
  //Verification
  { path: '/verify-email/:token', name: 'Verification', component: () => import('@/views/Auth/VerificationEmail.vue') },
  { path: '/reset-password/:token', name: 'ResetVerification', component: () => import('@/views/Auth/VerificationEmail.vue') },
  // Auth
  { path: '/sign-in', name: 'SignIn', component: () => import('@/views/Auth/SignIn.vue')},
  // Sign up
  {
    path: '/sign-up',
    name: 'SignUp',
    redirect: '/sign-up/email',
    component: () => import('@/views/Auth/SignUp/index.vue'),
    children: [
      { path: 'email', name: 'SignUpEmail', component: () => import('@/views/Auth/SignUp/EmailPage.vue') },
    ]
  },
  // Account creation paths
  {
    path: '/account-creation',
    component: () => import('@/views/Auth/AccountCreation/index.vue'),
    redirect: '/account-creation/user',
    name: 'AccountCreation',
    beforeEnter: handleAccountCreation,
    children: [
      { path: 'user', component: () => import('@/views/Auth/AccountCreation/UserPage.vue') },
      { path: 'survey', component: () => import('@/views/Auth/AccountCreation/SurveyPage.vue'), beforeEnter: checkAuthUserFirstNameAndLastName, },
      { path: 'organization', component: () => import('@/views/Auth/AccountCreation/OrganizationPage.vue'), beforeEnter: checkAuthUserFirstNameAndLastName, },
      { path: 'plan', component: () => import('@/views/Auth/AccountCreation/PlanPage.vue'), beforeEnter: checkAuthUserFirstNameAndLastName, },
      { path: 'payment', component: () => import('@/views/Auth/AccountCreation/PaymentPage.vue'), beforeEnter: checkAuthUserFirstNameAndLastName, },
    ]
  },
  // Reset Flow paths
  { path: '/reset-password', name: 'ResetPassword', component: () => import('@/views/Auth/ResetPassword.vue')},
  { path: '/set-password', name: 'SetPassword', component: () => import('@/views/Auth/SetPassword.vue')},

  { path: '/email-sent', name: 'EmailSent', component: () => import('@/views/Auth/EmailSent.vue')},
  { path: '/link-expired', name: 'LinkExpired', component: () => import('@/views/Auth/LinkExpired.vue')},
]
