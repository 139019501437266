import { handleInventory, handleListingsDetails, handleListings } from '../helpers/routes'

export default [
  {
    path: '/inventory',
    name: 'Inventory',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Inventory/index.vue'),
    beforeEnter: handleInventory
  },
  {
    path: '/inventory/:cardId',
    name: 'InventoryDetails',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/InventoryDetails/index.vue'),
  },
  {
    path: '/listings/:connection/:cardId',
    meta: { requireAuth: true, requireFields: true },
    name: 'ListingsDetails',
    component: () => import('@/views/ListingsDetails/index.vue'),
    sensitive: true,
    beforeEnter: handleListingsDetails,
  },
  {
    path: '/listings',
    name: 'Listings',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Listings/index.vue'),
    beforeEnter: handleListings,
    children: [
      {
        path: ':connection',
        component: () => import('@/views/Listings/ListingsByConnection.vue'),
        sensitive: true,
      }
    ]
  }
]
