<script setup>
import { useBatchStore } from "@/stores/BatchStore";

const batchStore = useBatchStore();

const emit = defineEmits(["upgrade"]);
</script>

<template>
  <button
    @click="$emit('upgrade')"
    class="hidden md:flex items-center gap-x-1.5 pl-1.5 pr-2.5 py-2 transition duration-300 bg-blue-50 text-tint-1 rounded"
    :class="{ '!p-0': !batchStore.config?.subscription?.plan_id }"
    aria-label="Handle Upgrade"
  >
    <template
      v-if="
        batchStore.config?.subscription?.plan_id &&
        (!batchStore.config?.subscription ||
          (batchStore.config?.hasActiveSubscription &&
            batchStore.config?.subscription?.plan_id !== batchStore.topPlan?.id))
      "
    >
      <Icon icon="heroicons:fire-16-solid" />
      <span class="text-xs font-semibold">Upgrade</span>
    </template>
    <template
      v-else-if="
        batchStore.config?.subscription?.plan_id &&
        batchStore.config?.subscription &&
        !batchStore.config?.hasActiveSubscription
      "
    >
      <Icon icon="heroicons:bolt" />
      <span class="text-xs font-semibold">Reactivate Account</span>
    </template>
    <template v-else>
      <div class="w-[88px] invisible h-0"></div>
    </template>
  </button>
</template>
