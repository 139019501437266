import { defineStore, acceptHMRUpdate } from 'pinia'

import { apiClient, apiBuildTxt } from "@/helpers/api.js"
import { modifiedDateStringForBuild } from "@/helpers/datetime.js"
import { useUserStore } from './UserStore'

export const useGlobalStore = defineStore('GlobalStore', {
  state: () => {
    return {
      windowWidth: null,
      windowHeight: null,
      sidebar: false,
      userAgent: '',
      pusherLoading: false,
      openPlansModal : false,
      config: {},
      hideAdminBanner: false,
      stripe: null,
      card: null, // XXX stripe card element? need better name given our app is all about "cards". maybe stripeCard?
      sendFeedbackLoader: false,
      sendFeedbackError: false,
      hasNewVersion: false,
      errors: null,
      filters: [],
      saveActiveFilterFor: {
        activeFilters: '',
        viewOption: '',
        sort: '',
        displayColumns: '',
        columnState: ''
      },
      agBodyScroll: {
        popover: {
          old: { top: 0, left: 0 },
          new: { top: 0, left: 0 },
        },
      },
      cellsLoading: false,
      replaceImageLoader: false,
      mobileScanOpened: false
    }
  },

  getters: {
    showAdminBanner() {
      const userStore = useUserStore()
      return !this.hideAdminBanner && userStore.overrideAuth
    }    
  },

  actions: {
    async postFeedback(body) {
      try {
        this.sendFeedbackError = false
        this.sendFeedbackLoader = true
        const { data, response } = await apiClient(false, true).post('/feedback', body)

        if (!data || !data.ok) {
          this.sendFeedbackError = true
        }

        this.sendFeedbackLoader = false
      } catch (error) {
        console.log('postFeedback error', error)
        this.sendFeedbackLoader = false
        this.sendFeedbackError = true
      }
    },

    async fetchBuildTxt() {
      try {
        const { data } = await apiBuildTxt().get(`/build.txt?ts=${new Date().getTime()}`)
        
        const buildAttribute = document.body.getAttribute('build')

        if (!buildAttribute || !data) return

        const modifiedDateFromServer = modifiedDateStringForBuild(data)
        const modifiedBuildAttribute = modifiedDateStringForBuild(buildAttribute)



        if (new Date(modifiedBuildAttribute) < new Date(modifiedDateFromServer)) {
          this.hasNewVersion = true
        }

      } catch (error) {
        console.log('fetchBuildTxt error', error)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}