import { TAILWIND_FONT_SIZES } from '@/constants'

function capitalize(string) {
  if (!string) return ''
 return string.charAt(0).toUpperCase() + string.slice(1);
}  
  
const stripHtml = (html) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const truncateHtml = (markup, length = 200) => {
  const text = stripHtml(markup)
  const words = text.split(/[ \t\r\n]+/).map(word => word.trim())

  let output = ''
  for (let i = 0; i < words.length; i++) {
    if (output.length < length) {
      if (output.length + words[i].length < length) {
        output += `${words[i]} `
      }
    }
  }

  const trimmedOutput = output.trim()

  if (trimmedOutput) {
    return trimmedOutput.concat('&hellip;')
  }
  return ''
}

const cleanUrl = (url) => {
  if (url.indexOf('http') !== -1) {
    return url
  }
  return `https://${url}`
}

const formatCurrency = (amount) => {
  return Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2
  });
}

const desnake = (str) => {
  if (!str) return ''
  return str.replace(/_/g, ' ')
}

const cleanStatus = (rawStatus) => {
  return capitalize(desnake(rawStatus))
}

const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase()
  }).replace(/\s+/g, '')
}

const replaceMarkdownText = (text, color) =>{
  text = text.replace(/\n/g,`<br/>`)
  text = text.replace(/_(.*?)_/g, '<em>$1</em>')
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
  text = text.replace(
    /(^|\n|<br\s*\/?>)\s*(#{1,6})\s*(.+?)(?=\n|<br\s*\/?>|$)/g,  // Enhanced regex to capture headings and content correctly
    (match,leading, hashes, content) => {
      const level = hashes.length; // Get the number of hashes
      return `${leading}<h${level} class="${TAILWIND_FONT_SIZES[level]}">${content.trim()}</h${level}>\n`;
    }
  )

  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g

  return text.replace(linkRegex, (matchSubstring, linkText, url) => {
    if (url.includes('png')) {
      return `<img
        src="${url.replace(/<em>|<\/em>/g, '_')}"
        class="w-full h-72 object-cover rounded-xl"
      />`
    }

    const target = url.includes('http') ? '_blank' : '_self';
    return `<a href="${url}" target="${target}" style="text-decoration: underline; color: ${color};">${linkText}</a>`;
  })
}

const formatYoutubeURL = (url) => {
  let url_ = url
  const substringToReplace = 'https://www.youtube.com/watch?v='
  const substringV2ToReplace = 'https://youtu.be'
  const replacementSubstring = 'https://www.youtube.com/embed/'

  if (url_.includes('www.youtube.com') && url_.includes('youtu.be')) {
    url_ = url_.split('&')[0]
  }

  if(url_.includes(substringToReplace)) {
    return url_.replace(substringToReplace, replacementSubstring)
  }

  if(url_.includes(substringV2ToReplace)) {
    return url_.replace(substringV2ToReplace, replacementSubstring)
  }

  return replacementSubstring + url_
}

const getPlural = (word) => {
  // Check if the word already ends with 's'
  if (word.endsWith('s')) {
    return word; // Return the word as is
  } else {
    // Check if the word ends with 'x', 'z', 'ch', or 'sh'
    if (word.endsWith('x') || word.endsWith('z') || word.endsWith('ch') || word.endsWith('sh')) {
      return word + 'es'; // Add 'es' for plural
    } else {
      return word + 's'; // Add 's' for plural
    }
  }
}

export {
    capitalize,
    desnake,
    cleanStatus,
    stripHtml,
    truncateHtml,
    cleanUrl,
    formatCurrency,
    camelize,
    replaceMarkdownText,
    formatYoutubeURL,
    getPlural
}