import { handleBatchAdd, handleCreateBatch } from '../helpers/routes'

export default [
  {
    path: '/batches/:batchId/add',
    meta: { requireAuth: true },
    component: () => import('@/views/Batches/AddCards/index.vue'),
    redirect: (route) => {
      return `${route.fullPath}/types`
    },
    beforeEnter: handleBatchAdd,
    children: [
      {
        path: 'types',
        name: 'AddTypes',
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/AddCardOptions.vue')
      },
      {
        path: 'sides',
        name: 'AddSides',
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/ChooseSides.vue')
      },
      {
        path: 'upload',
        name: 'AddUpload',
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/UploadImages.vue')
      },
      {
        path: 'cert',
        name: 'AddCert',
        meta: { requireAuth: true },
        component: () => import('@/views/Batches/AddCards/CertImport.vue')
      },
      {
        path: 'scan',
        name: 'AddScan',
        component: () => import('@/views/Batches/AddCards/MobileScan.vue')
      },
    ]
  },
  {
    path: '/batches',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('./RouterView.vue'),
    children: [
      { path: '', name: 'Batches', component: () => import('@/views/Batches/AllBatches/index.vue') },
      {
        path: ':batchId',
        component: () => import('@/views/Batches/BatchDetails/index.vue'),
        name: 'BatchDetailsFlow',
        children: [
          { path: '', name: 'BatchDetail', component: () => import('@/views/Batches/BatchDetails/index.vue') },
          {
            path: 'inspector',
            component: () => import('@/views/Batches/BatchDetails/index.vue'),
            children: [
              { path: '', name: 'InspectorDetail', component: () => import('@/views/Batches/BatchDetails/index.vue') },
              { path: ':cardId', name: 'InspectorDetailWithCardId', component: () => import('@/views/Batches/BatchDetails/index.vue') },
            ]
          },
          {
            path: ':cardId',
            component: () => import('@/views/Batches/BatchDetails/index.vue'),
            children: [
              { path: '', name: 'BatchDetailByCard', component: () => import('@/views/Batches/BatchDetails/index.vue') },
              { path: ':tab', name: 'InspectorDetailByCard', component: () => import('@/views/Batches/BatchDetails/index.vue') },
            ]
          },

        ]
      },
    ]
  },
  {
    path: '/create-batch',
    meta: { requireAuth: true, requireFields: true },
    component: () => import('@/views/Batches/CreateBatch/index.vue'),
    redirect: '/create-batch/general-settings',
    beforeEnter: handleCreateBatch,
    children: [
      { path: 'general-settings', component: () => import('@/views/Batches/CreateBatch/GeneralSettings.vue') },
      { path: 'optional-details', component: () => import('@/views/Batches/CreateBatch/OptionalDetails.vue') },
      // { path: 'add-card-options', component: () => import('@/views/Batches/CreateBatch/AddCardOptions.vue') },
      // { path: 'choose-sides', component: () => import('@/views/Batches/CreateBatch/ChooseSides.vue') },
      // { path: 'upload-images', component: () => import('@/views/Batches/CreateBatch/UploadImages.vue') },
    ]
  }
]
