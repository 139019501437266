<script setup>
import { useGlobalStore } from '@/stores/GlobalStore'

const globalStore = useGlobalStore()

const reloadApp = () => {
  globalStore.hasNewVersion = false
  window.location.reload()
}
</script>

<template>
  <Transition name="fade">
    <section
      class="fixed bottom-4 left-20 shadow rounded-lg p-4 bg-white w-[372px] text-sm z-[1099]"
      v-if="globalStore.hasNewVersion"
    >
      <section class="flex items-start gap-x-3">
        <span class="p-1.5 rounded-lg bg-blue-100 flex-none">
          <Icon icon="heroicons:arrow-path" class="text-xl text-tint-1" />
        </span>
        <section>
          <header class="flex items-start justify-between mb-1">
            <h3 class="font-semibold">New version available</h3>
            <button @click="globalStore.hasNewVersion = false">
              <Icon icon="heroicons:x-mark" class="text-base" />
            </button>
          </header>
          <p class="text-gray-600 text-xs mb-3">
            An improved version of Card Dealer Pro is available. Please reload this window now to update.
          </p>
          <button
            class="font-semibold text-tint-1 transition hover:text-tint-2"
            @click="reloadApp"
          >
            Reload
          </button>
        </section>
      </section>
    </section>
  </Transition>
</template>