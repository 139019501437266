import { defineStore, acceptHMRUpdate } from 'pinia'
import { apiClient } from '@/helpers/api.js'
import { useUserStore } from '@/stores/UserStore'
import { cleanStatus } from '@/helpers/text'

export const useListingsStore = defineStore('ListingsStore', {
  state: () => {
    return {
      activeConnectionData: null,
      searchActive: false,
      searchQuery: '',
      listingsLoading: false,
      listingsView: 'Board',
      openedListingDialog: false,
      openFullScreenImage: false,
      imageForFullScreen: null,
      selectedCardData: null,
      itemFilters: {},
      listings: [
        {
          "id":3717548,
          "viewed":true,
          "inventory": 2,
          "batch_id": 'Prospects vol2',
          "is_loading":false,
          "has_errors":false,
          "errors":[],
          "has_banner":0,
          "has_suggested_edits":false,
          "suggested_edits":[],
          "green":true,
          "yellow":false,
          "red":false,
          "select":false,
          "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
          "back_image":null,
          "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
          "back_image_sm":null,
          "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
          "back_image_md":null,
          "title":"1990 Frank Thomas Chicago White Sox",
          "template_title":"1990 Frank Thomas Chicago White Sox",
          "template_title_id":15964,
          "collx_type_id": 1,
          "template_description_id":null,
          "database_match":{
            "id":202794,
            "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
            "back_image":null,
            "title":"1990 Score Rookie & Traded #86T Frank Thomas",
            "card_number":"86T",
            "player":"Frank Thomas",
            "year":1990,
            "set":"1990 Score Rookie & Traded",
            "setId":4337,
            "brand":"Score",
            "subset":null,
            "variation":null,
            "serialNumber":null
          },
          "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"not_for_sale","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":1,"grader":'PSA',"condition":"Near Mint or Better","cert_no":4345345345,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":true, ebay_listed_at: true, shopify_listed_at: false},
          {
            "id":3717541,
            "viewed":true,
            "inventory": 1,
            "is_loading":false,
            "batch_id": 'CollX Import',
            "has_errors":false,
            "errors":[],
            "has_banner":0,
            "has_suggested_edits":false,
            "suggested_edits":[],
            "green":true,
            "yellow":false,
            "red":false,
            "select":false,
            "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
            "back_image":null,
            "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
            "back_image_sm":null,
            "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
            "back_image_md":null,
            "title":"1991 Frank Thomas Chicago White Sox",
            "template_title":"1991 Frank Thomas Chicago White Sox",
            "template_title_id":15964,
            "template_description_id":null,
            "database_match":{},
            "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"accepting_offers","start_price":3,"collx_match_id":null,"sport":"Yu-Gi-Oh","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":'Gold Rare',"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"tcg","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
            {
              "id":3717542,
              "viewed":true,
              "inventory": 2,
              "is_loading":false,
              "batch_id": 'MTrout 2',
              "has_errors":false,
              "errors":[],
              "has_banner":0,
              "has_suggested_edits":false,
              "suggested_edits":[],
              "green":true,
              "yellow":false,
              "red":false,
              "select":false,
              "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
              "back_image":null,
              "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
              "back_image_sm":null,
              "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
              "back_image_md":null,
              "title":"1992 Frank Thomas Chicago White Sox",
              "template_title":"1992 Frank Thomas Chicago White Sox",
              "template_title_id":15964,
              "template_description_id":null,
              "database_match":{
                "id":202794,
                "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
                "back_image":null,
                "title":"1990 Score Rookie & Traded #86T Frank Thomas",
                "card_number":"86T",
                "player":"Frank Thomas",
                "year":1990,
                "set":"1990 Score Rookie & Traded",
                "setId":4337,
                "brand":"Score",
                "subset":null,
                "variation":null,
                "serialNumber":null
              },
              "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"for_sale","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
              {
                "id":3717543,
                "viewed":true,
                "inventory": 3,
                "is_loading":false,
                "has_errors":false,
                "batch_id": 'MTrout 2',
                "errors":[],
                "has_banner":0,
                "has_suggested_edits":false,
                "suggested_edits":[],
                "green":true,
                "yellow":false,
                "red":false,
                "select":false,
                "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
                "back_image":null,
                "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
                "back_image_sm":null,
                "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
                "back_image_md":null,
                "title":"1993 Frank Thomas Chicago White Sox",
                "template_title":"1993 Frank Thomas Chicago White Sox",
                "template_title_id":15964,
                "template_description_id":null,
                "database_match":{
                  "id":202794,
                  "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
                  "back_image":null,
                  "title":"1990 Score Rookie & Traded #86T Frank Thomas",
                  "card_number":"86T",
                  "player":"Frank Thomas",
                  "year":1990,
                  "set":"1990 Score Rookie & Traded",
                  "setId":4337,
                  "brand":"Score",
                  "subset":null,
                  "variation":null,
                  "serialNumber":null
                },
                "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"sold_on","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
                {
                  "id":3717544,
                  "viewed":true,
                  "inventory": 2,
                  "is_loading":false,
                  "has_errors":false,
                  "errors":[],
                  "batch_id": 'MTrout 2',
                  "has_banner":0,
                  "has_suggested_edits":false,
                  "suggested_edits":[],
                  "green":true,
                  "yellow":false,
                  "red":false,
                  "select":false,
                  "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009",
                  "back_image":null,
                  "front_image_sm":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_sm",
                  "back_image_sm":null,
                  "front_image_md":"https://storage.googleapis.com/collx-media/202794-front.jpg?_t=1716996593009fr_md",
                  "back_image_md":null,
                  "title":"1994 Frank Thomas Chicago White Sox",
                  "template_title":"1994 Frank Thomas Chicago White Sox",
                  "template_title_id":15964,
                  "template_description_id":null,
                  "database_match":{
                    "id":202794,
                    "front_image":"https://storage.googleapis.com/collx-media/202794-front.jpg",
                    "back_image":null,
                    "title":"1990 Score Rookie & Traded #86T Frank Thomas",
                    "card_number":"86T",
                    "player":"Frank Thomas",
                    "year":1990,
                    "set":"1990 Score Rookie & Traded",
                    "setId":4337,
                    "brand":"Score",
                    "subset":null,
                    "variation":null,
                    "serialNumber":null
                  },
                  "matches":[{"id":202794,"set":{"id":4337,"name":"1990 Score Rookie & Traded","year":1990,"parent":null,"subset":null},"name":"Frank Thomas","note":null,"team":{"id":12,"name":"Chicago White Sox"},"year":1990,"brand":{"id":7,"name":"Score"},"flags":null,"sport":"BASEBALL","number":"86T","player":{"id":1679,"name":"Frank Thomas"},"distance":0,"variation":null,"backImageUrl":"https://storage.googleapis.com/collx-media/202794-back.jpg","serialNumber":null,"frontImageUrl":"https://storage.googleapis.com/collx-media/202794-front.jpg"}],"status":"sold_out","start_price":3,"collx_match_id":202794,"sport":"BASEBALL","year":"1990","brand":"Score","player":"Frank Thomas","card_number":"86T","subset":null,"attributes":null,"team":"Chicago White Sox","set":"1990 Score Rookie & Traded","variation":null,"graded":null,"grader":null,"condition":"Near Mint or Better","cert_no":null,"grade_name":null,"grade_number":null,"purchase_price":null,"purchase_date":null,"note":null,"inventory_location":"FIXME","cabinet":null,"shelf":null,"box":null,"row":null,"section":null,"sku":"ABC10000000376","description":null,"description_plain_text":null,"template_description_plain_text":"This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  Other important information about this card:In 1990 Frank Thomas played for the Chicago White Sox.Please check out our other listings for more great cards.","template_description":"<p>This is a nice example of a 1990 Score Rookie & Traded #86T Frank Thomas  <br><br><br>Other important information about this card:<br><br><br>In 1990 Frank Thomas played for the Chicago White Sox.<br><br><br>Please check out our other listings for more great cards.</p>","type":"sports","customFieldsListForInspector":null,"frontOrientation":"portrait","backOrientation":"portrait","serial_number":null,"cert_data":null,"collx_listed_at":null},
      ]
    }
  },

  getters: {
    listingsByStatus () {
      if (!this.listings.length) return []

      return Object.entries(this.listings.reduce((acc, item) => {
        if (acc[item.status]) {
          acc[item.status].values.push(item)
        } else {
          acc[item.status] = {
            label: cleanStatus(item.status),
            key: item.status,
            show: true,
            values: [item]
          }

          return acc
        }
      }, {})).map(([key, value]) => value)
    }
  },

  actions: {
    async fetchSelectedCards(query, turnOnLoader = true) {
      // const userStore = useUserStore()
      // const globalStore = useGlobalStore()

      // if (turnOnLoader) {
      //   this.batchLoading = true
      // }

      // console.log('fetchSelectedCards')
      // let _query = { ...query }

      // if (!_query.sortBy) {
      //   const savedBatchDetailsSort = JSON.parse(localStorage.getItem('batchDetailsSort'))

      //   _query = { ..._query, sortBy: savedBatchDetailsSort && savedBatchDetailsSort[userStore.user?.id] && savedBatchDetailsSort[userStore.user?.id].value || 'filename' }
      // }

      // await this.fetchConfigs()

      // this.selectedBatchName = null

      // try {
      //   const response = await apiClient().get(`/batches/${this.selectedBatchId}/cards`, _query ? {
      //     params: _query
      //   } : {}) 

      //   const data = response.data

      //   this.selectedBatchName = data.batch_name

      //   const batchItems = []
      //   data.items.forEach((card, index) => {
      //     const cardData = generateCardData(card, this.selectedRanges, this.config)
      //     batchItems.push(cardData)
      //   })

      //   this.batchItems = batchItems

      //   globalStore.cellsLoading = false
      //   this.batchImageCellsLoading = false
      // } catch (error) {
      //   console.error('fetchSelectedCards error', error)
      // } finally {
      //   this.batchLoading = false
      //   globalStore.cellsLoading = false
      //   this.batchImageCellsLoading = false
      // }
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useListingsStore, import.meta.hot))
}