import { icons } from '@/helpers/icons'

export const LISTINGS_FILTERS = {
  filter_blocks: [
    [
      { icon: { is_local: true, value: icons.status_spinner }, name: 'Status', key_name: 'status' },
      // { icon: { is_local: true, value: icons.text_T }, name: 'Content' }
    ],
    [
      { icon: { is_local: true, value: icons.icon_multisport }, name: 'Categories', field: 'categories', key_name: 'categories' },
      { icon: 'heroicons:tag', name: 'Brand', field: 'brand', key_name: 'brand' },
      { icon: 'heroicons:information-circle', name: 'Attribute', field: 'attributes', key_name: 'attribute' },
      { icon: 'heroicons:scale-20-solid', name: 'Graded', field: 'graded', key_name: 'graded' },
      { icon: 'heroicons:scale-20-solid', name: 'Grader', field: 'grader', key_name: 'grader' },
      // { icon: 'heroicons:currency-dollar', name: 'Price' },
      // { icon: 'heroicons:map-pin', name: 'Location' },
    ],
  ]
}

export const PRODUCT_DETAILS_FIELDS = [
  {
    field: 'set',
    label: 'Set',
    type: 'text',
  },
  {
    field: 'team',
    label: 'Team',
    type: 'text',
  },
  {
    field: 'year',
    label: 'Year',
    type: 'number',
  },
  {
    field: 'brand',
    label: 'Brand',
    type: 'text',
  },
  {
    field: 'card_number',
    label: 'Card Number',
    type: 'number',
  },
  {
    field: 'player',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'attributes',
    label: 'Attributes',
    type: 'text',
  },
  {
    field: 'sports',
    label: 'Category',
    type: 'text',
  },
  {
    field: 'subset',
    label: 'Subset / Parallel',
    type: 'text'
  },
  {
    field: 'variation',
    label: 'Variation',
    type: 'text',
  },
  {
    field: 'serial_number',
    label: 'Serial Number',
    type: 'text',
  },
]