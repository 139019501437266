<script setup>
import { useSearchStore } from '@/stores/SearchStore'
import { CARD_TYPES } from '@/constants'
import moment from 'moment'

const props = defineProps({
  active: { type: Boolean, default: false },
  item: { type: Object, required: true },
  selectedCard: { type: Object, default: null }
})

const searchStore = useSearchStore()

</script>
<template>
<li
  class="flex items-center justify-between p-3 transition duration-300 hover:bg-blue-50 cursor-pointer group"
  :class="{ 'bg-blue-50': props.active }"
>
  <div class="flex items-center gap-x-3 w-full">
    <div
      class="w-10 pt-2 px-1 relative flex-shrink-0 group-pics-style"
    >
      <img src="@/assets/images/place-cards/1.png" />
      <img src="@/assets/images/place-cards/2.png" />
      <img src="@/assets/images/place-cards/3.png" />
    </div>
    <div class="flex-auto">
      <h3 class="font-medium">{{ props.item.name }}</h3>
      <p class="text-gray-500">{{CARD_TYPES[props.item.type]}} Card . Imported {{ moment(props.item.created_at).format('MMMM DD YYYY . h:mm:ss A') }}</p>
    </div>
    <button
      v-if="searchStore.cdp_search.locationOfSearchCall === 'add-button'"
      class="
        alternative-button text-xs py-1 text-tint-1 focus:ring-tint-1/20 transition duration-300 opacity-0
        group-hover:opacity-100
      "
      :class="{'!opacity-100': props.active}"
    >
      Add Cards
    </button>
    <button
      v-else-if="props.selectedCard"
      class="
        alternative-button text-xs py-1 text-tint-1 focus:ring-tint-1/20 transition duration-300 opacity-0
        group-hover:opacity-100
      "
      :class="{'!opacity-100': props.active}"
    >
      Add Selected Card
    </button>
  </div>
</li>
</template>