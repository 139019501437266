import { createRouter, createWebHistory } from 'vue-router'

import mobileScanRoutes from './mobileScanRoutes'
import authRoutes from './authRoutes'
import generalRoutes from './generalRoutes'
import batchRoutes from './batchRoutes'
import inventoryAndListingsRoutes from './inventoryAndListingsRoutes'
import connectionRoutes from './connectionRoutes'

import { handleBeforeEach } from '@/helpers/routes'

const routes = [
  ...mobileScanRoutes,
  ...authRoutes,
  ...generalRoutes,
  ...batchRoutes,
  ...inventoryAndListingsRoutes,
  ...connectionRoutes,

  // 404 not found page
  {
    path: '/:catchAll(.*)',
    name: 'NotFoundPage',
    component: () => import('@/views/NotFoundPage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  },
})

router.beforeEach(to => handleBeforeEach(to))

export default router
