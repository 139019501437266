import { computed, watch, ref } from 'vue'
import { useBatchStore } from '@/stores/BatchStore'
import { useUserStore } from '@/stores/UserStore'
import { useRoute } from 'vue-router'

export function useCello() {
  const batchStore = useBatchStore()
  const userStore = useUserStore()

  const route = useRoute()

  const celloAlreadyBooted = ref(false)

  const celloToken = computed(() => batchStore.config?.celloToken)

  watch(celloToken, () => {
    if (!celloToken.value || celloAlreadyBooted.value) return

    window.cello = window.cello || { cmd: [] }

    window.cello.cmd.push(async function (cello) {
      try {
        await cello.boot({
          productId: import.meta.env.VITE_CELLO_PRODUCT_ID,
          token: celloToken.value,
          language: "en",
          productUserDetails: {
            firstName: userStore.user?.first_name,
            lastName: userStore.user?.last_name,
            fullName: `${userStore.user?.first_name} ${userStore.user?.first_name}`,
            email: userStore.user?.email
          }
        })

        celloAlreadyBooted.value = true

        route.name !== 'Homepage' && window.Cello('hide')
      } catch (error) {
        console.error("Failed to boot cello:", error);
        // Handle the error appropriately
      }
    })
  })
}