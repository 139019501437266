<script setup>
import { useSearchStore } from '@/stores/SearchStore'

const props = defineProps({
  placeholder: { type: String, default: '' },
  items: { type: Object, default: [] },
  filter: { type: String, default: 'Batches' },
  buttonName: { type: String, default: '' } 
})

const searchStore = useSearchStore()
</script>
<template>
  <header class="flex items-center justify-between bg-gray-100 py-2 px-4">
    <h3 class="font-semibold text-gray-500">{{ props.placeholder }}</h3>
    <button
      class="inline-flex items-center gap-x-1 font-medium text-tint-1 transition hover:text-tint-2"
      @click="searchStore.selectedFilter = props.filter"
      v-if="!searchStore.selectedFilter && props.items.length > 3"
    >
      {{ buttonName }} <Icon icon="heroicons:chevron-right" class="text-base" />
    </button>
  </header>
</template>