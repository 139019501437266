export default [
  {
    path: '/mobile-scan/:batchId',
    name: 'MobileScan',
    component: () => import('@/views/MobileScan/index.vue'),
    children: [
      { path: 'sides', name: 'MobileScanSides',  meta: { requireAuth: true }, component: () => import('@/views/MobileScan/SelectSides.vue') },
      { path: 'uploads', name: 'MobileScanUpload', meta: { requireAuth: true }, component: () => import('@/views/MobileScan/UploadPhoto.vue') },
      { path: 'completed', name: 'MobileScanCompleted', meta: { requireAuth: true }, component: () => import('@/views/MobileScan/Completed.vue') }
    ]
  },
  {
    path: '/mobile-scan/session-ended',
    name: 'SessionMobileScanEnded',
    meta: { requireAuth: false },
    component: () => import('@/views/MobileScan/SessionEnded.vue')
  }
]
