/**
 * * command + K -> open global search
 * * command + Enter -> for Inspector view -> Mark as Ready to List
 */
import { useBatchStore } from '@/stores/BatchStore'
import { useSearchStore } from '@/stores/SearchStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { BATCH_DETAILS_VIEW_OPTIONS } from '@/constants'
import { getNextCardId, toggleAllRows } from '@/helpers/batch'
import { useRoute } from 'vue-router'

// let nKey = false
// let bKey = false
// let gKey = false
// let hKey = false
// let iKey = false
// let aKey = false
// let dKey = false
// let cKey = false
// let questionKey = false
// let bracket = false

export const useKeyboardShortcut = (router) => {
  const batchStore = useBatchStore()
  const searchStore = useSearchStore()
  const globalStore = useGlobalStore()

  const route = useRoute()

  document.body.addEventListener('keydown', (event) => {
    // Check if the Cmd key (macOS) or Ctrl key (Windows/Linux) is pressed along with the 'K' key
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault()
      searchStore.cdp_search = {
        ...searchStore.cdp_search,
        open: true,
        locationOfSearchCall: ''
      }
    }

    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter' && batchStore.selectedBatchDetailsViewOption === BATCH_DETAILS_VIEW_OPTIONS[1].value && batchStore.selectedCardData?.status === 'draft' && !batchStore.selectedCardData?.has_errors) {
      batchStore.updateCardStatus(batchStore.selectedCardId, 'ready_to_list')
      batchStore.updatedSelectedCard(getNextCardId())
    }

  //   console.log('keydown')


  //   if (event.key === 'n') {
  //     nKey = true
  //   } 

  //   if (event.key === 'b') {
  //     bKey = true
  //   } 
    
  //   if (event.key === 'g') {
  //     gKey = true
  //   }
    
  //   if (event.key === 'h') {
  //     hKey = true
  //   }
    
  //   if (event.key === 'i') {
  //     iKey = true
  //   }

  //   if (event.key === 'a') {
  //     aKey = true
  //   }

  //   if (event.key === 'c') {
  //     cKey = true
  //   }

  //   if (event.key === '?') {
  //     questionKey = true
  //   }

  //   if (event.key === '[') {
  //     bracket = true
  //   }

  //   // Create new batch
  //   if (nKey && bKey) {
  //     console.log('Both "n" and "b" keys were pressed simultaneously.');
  //   }

  //   // Go to Home
  //   if (gKey && hKey) {
  //     router.push('/')
  //   }

  //   // Go to Batches
  //   if (gKey && bKey) {
  //     router.push('/batches')
  //   }

  //   // Go to Inventory
  //   if (gKey && iKey) {
  //     router.push('/inventory')
  //   }

  //   // Go to Account Settings
  //   if (gKey && aKey) {
  //     router.push('/settings/account')
  //   }

  //   // Go to Connections
  //   if (gKey && cKey) {
  //     router.push('/connections')
  //   }

  //   // Open Help
  //   if (event.shiftKey && questionKey) {
  //     window.open('https://support.carddealerpro.com/hc/en-us','_blank')
  //   }

  //   // Collapse Sidebar
  //   if (bracket) {
  //    globalStore.sidebar = !globalStore.sidebar
  //   }

  //   // Select All
  //   if ((event.metaKey || event.ctrlKey) && aKey && route.name === 'BatchDetail') {
  //     event.preventDefault()
  //     event.stopPropagation()
  //     toggleAllRows()
  //   }
  })
}

// document.body.addEventListener('keyup', () => {
//   console.log('keyup')
//   nKey = false
//   bKey = false
//   gKey = false
//   hKey = false
//   iKey = false
//   aKey = false
//   questionKey = false
//  })

export const attachEventListener = (cb) => {

  document.body.addEventListener('keydown', cb)
}

export const detachEventListener = (cb) => {
  document.body.removeEventListener('keydown', cb)
}