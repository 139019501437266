<script setup>
import { useBatchStore } from "@/stores/BatchStore";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

const props = defineProps({
  user: Object,
});

const emit = defineEmits(["closeSidebar", "upgrade", "signOut"]);
const batchStore = useBatchStore();
</script>

<template>
  <Menu as="div" class="relative inline-block text-left" v-slot="{ open, close }">
    <MenuButton class="flex items-center gap-x-0.5" @click="$emit('closeSidebar')">
      <img
        v-if="user.avatar_image"
        :src="user.avatar_image"
        class="w-8 h-8 object-cover rounded-full ring-1 ring-blue-600"
      />
      <span
        v-else
        class="w-8 h-8 object-cover rounded-full ring-1 ring-blue-600 flex items-center justify-center"
        style="color: #0086ff; background-color: #ebf5ff"
        v-text="user.first_name?.slice(0, 1).toUpperCase()"
      />
      <Icon
        icon="heroicons:chevron-down-16-solid"
        class="text-2xl text-white transition-all duration-300"
        :class="{ 'rotate-180': open }"
      />
    </MenuButton>
    <Transition name="menu-scale">
      <MenuItems
        class="z-10 bg-white divide-y divide-gray-100 rounded-md shadow-sm border w-[14rem] absolute top-full right-0 translate-y-0.5 text-sm text-gray-500"
        as="ul"
      >
        <li class="px-4 py-3">
          <h4
            class="font-semibold text-gray-900"
            v-html="`${user.first_name} ${user.last_name}`"
          ></h4>
          <span class="font-medium truncate" v-html="user.email"></span>
        </li>
        <div class="py-1">
          <MenuItem as="li">
            <router-link
              @click="[$emit('closeSidebar'), close()]"
              to="/settings/account"
              class="block px-4 py-2 transition duration-300 hover:bg-gray-100"
            >
              Account Settings
            </router-link>
          </MenuItem>
          <MenuItem as="li">
            <router-link
              @click="[$emit('closeSidebar'), close()]"
              to="/settings/plans"
              class="block px-4 py-2 transition duration-300 hover:bg-gray-100"
            >
              Billing
            </router-link>
          </MenuItem>
          <MenuItem as="li">
            <a
              href="https://www.carddealerpro.com/trading-card-scanner"
              target="_blank"
              @click="[$emit('closeSidebar'), close()]"
              to="/"
              class="block px-4 py-2 transition duration-300 hover:bg-gray-100"
            >
              Get the Desktop App
            </a>
          </MenuItem>
        </div>
        <MenuItem
          as="li"
          class="py-1"
          v-if="
            batchStore.config?.hasActiveSubscription &&
            batchStore.config?.subscription?.plan_id !== batchStore.topPlan?.id
          "
        >
          <div
            @click="[$emit('closeSidebar'), close()], $emit('upgrade')"
            class="flex gap-x-1.5 px-4 py-2 transition duration-300 hover:bg-gray-100 cursor-pointer"
          >
            <Icon icon="heroicons:fire-16-solid" class="text-base text-tint-1" />
            <span class="flex-grow">Upgrade</span>
          </div>
        </MenuItem>
        <MenuItem
          as="li"
          class="py-1"
          v-if="
            batchStore.config?.subscription && !batchStore.config?.hasActiveSubscription
          "
        >
          <div
            @click="[$emit('closeSidebar'), close()], $emit('upgrade')"
            class="flex gap-x-1.5 px-4 py-2 transition duration-300 hover:bg-gray-100 cursor-pointer"
          >
            <Icon icon="heroicons:bolt-16-solid" class="text-base text-tint-1" />
            <span class="flex-grow">Reactivate Account</span>
          </div>
        </MenuItem>
        <MenuItem as="li" class="py-2">
          <router-link
            to="/sign-in"
            @click="[$emit('signOut'), close()]"
            class="block w-full text-left px-4 py-2 text-red-600 transition duration-300 hover:bg-red-50"
          >
            Log out
          </router-link>
        </MenuItem>
      </MenuItems>
    </Transition>
  </Menu>
</template>
