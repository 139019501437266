import * as Sentry from "@sentry/vue"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

export const SentryInit = (app) => {
  
  Sentry.init({
    beforeSend(event) {
      if (event.exception.values[0].value.includes("Image failed to load!And failed src was")) {
        return false
      }

      return event
    },
    app,
    dsn: SENTRY_DSN,
    
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "http://dev.cdp2.torpedo.io", /^https:\/\/yourserver\.io\/api/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [/^Image failed to load!And failed src was$/],
  })
}