import moment from 'moment'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


const yyyymmddToUsDateFormat = (date) => {
  if (date) {
      const parts = date.split(/-/)
      return `${parseInt(parts[1])}/${parseInt(parts[2])}/${parseInt(parts[0])}`    
  }
  return null
}

const timestampToDateText = (date, centuryOffset = 2000) => {
  if (!date) return null

  const dateObj = new Date(date)
  const month = months[dateObj.getMonth()]

  return `${month} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

const timeDiffInSec = (startDateTime, endDateTime) => {
  const start = moment(startDateTime)
  let end = moment(new Date())
  if (endDateTime) {
    end = moment(endDateTime)
  }

  return end.diff(start, 'seconds')
}

const modifiedDateStringForBuild = (dateString) => {
  const parts = dateString.split('-');
  const modifiedDateString = parts.slice(0, 3).join('-') + 'T' + parts.slice(3).join('-');

  return modifiedDateString
}

export {
  yyyymmddToUsDateFormat,
  timestampToDateText,
  timeDiffInSec,
  modifiedDateStringForBuild
}