export const QUICK_ACTIONS = [
  // BATCHES
  // { text: 'Create new blank batch',       shortcut: [ 'N', 'B' ] },
  // { text: 'New magic scan',               shortcut: [ 'N', 'S' ] },

  // FILTER
  // { text: 'Open filter menu',             shortcut: [ 'F' ] },

  // Navigation
  { text: 'Go to Home',             shortcut: [ 'G', 'H' ] },
  { text: 'Go to Batches',             shortcut: [ 'G', 'B' ] },
  { text: 'Go to Inventory',             shortcut: [ 'G', 'I' ] },
  { text: 'Go to Channel Settings',             shortcut: [ 'G', 'C' ] },
  { text: 'Go to Account Settings',             shortcut: [ 'G', 'A' ] },

  // HELP
  { text: 'Open help',             shortcut: [ '?' ] },

  // MISC
  { text: 'Collapse sidebar',             shortcut: [ '[' ] },
  { text: 'Select all',                   shortcut: [ 'cmd', 'A' ] },
  { text: 'Open display settings',        shortcut: [ 'D' ] },
  // { text: 'Cancel',        shortcut: [ 'esc' ] },
  
  // Only available in batch details
  // { text: 'Mark item status',        shortcut: [ 'TBD' ] },
  // { text: 'Filter',        shortcut: [ 'F' ] },
  // { text: 'Toggle Inspector & Overview mode',        shortcut: [ 'TBD' ] },
  // { text: 'Export to channel',        shortcut: [ 'TBD' ] },
  // { text: 'Change batch status',        shortcut: [ 'TBD' ] },
  // { text: 'Edit batch banner',        shortcut: [ 'TBD' ] },
  // { text: 'Open batch settings',        shortcut: [ 'TBD' ] },
  // { text: 'Delete batch',        shortcut: [ 'TBD' ] },

  // Only available in when detail panel is expanded
  // { text: 'Go to Card Attributes',        shortcut: [ 'TBD' ] },
  // { text: 'Go to Grading & Condition',        shortcut: [ 'TBD' ] },
  // { text: 'Go to Pricing & Inventory',        shortcut: [ 'TBD' ] },
  // { text: 'Go to Title & Description',        shortcut: [ 'TBD' ] },
  // { text: 'Go to Notes',        shortcut: [ 'TBD' ] },
  // { text: 'Close panel',        shortcut: [ 'TBD' ] },
  // { text: 'Toggle image preview',        shortcut: [ 'TBD' ] },
]

export const FAKE_PLAYERS_DATA = [
  'Fernando Abad',
  'Andrew Abbott',
  'Cory Abbott',
  'Shane Bieber',
  'Brandon Bielak',
  'Cavan Biggio',
  'Jake Bird',
  'Ty Blach',
  'Paul Blackburn',
  'Charlie Blackmon',
  'Dairon Blanco',
  'Ronel Blanco',
  'JJ Bleday',
  'Richard Bleier',
  'Xander Bogaerts',
  'Alec Bohm',
  'Cody Bolton',
  'Ryan Borucki',
  'Brad Boxberger',
  'Matthew Boyd',
  'Connor Brogdon',
  'Mike Brosseau',
  'Hunter Brown',
  'Seth Brown',
  'Justin Bruihl',
  'Vidal Brujan',
  'Kris Bryant',
  'Kris Bubic',
  'J.B. Bukauskas',
  'Madison Bumgarner',
  'Aaron Bummer',
  'Nick Burdi',
  'Zack Burdi',
  'Peyton Burdick',
  'Jake Burger',
  'Brock Burke',
  'Alec Burleson',
  'Corbin Burnes',
  'Michael Busch',
  'Alan Busenitz',
  'Matt Bush',
  'Lawrence Butler',
  'Jose Butto',
  'Byron Buxton',
]

export const FAKE_TEAMS_DATA = [
  'Arizona Diamondbacks',
  'Atlanta Braves',
  'Baltimore Orioles',
  'Boston Red Sox',
  'Chicago Cubs',
  'Chicago White Sox',
  'Cincinnati Reds',
  'Cleveland Guardians',
  'Colorado Rockies',
  'Detroit Tigers',
  'Houston Astros',
  'Kansas City Royals',
  'Los Angeles Angels',
  'Los Angeles Dodgers',
  'Miami Marlins',
  'Milwaukee Brewers',
  'Minnesota Twins',
  'New York Mets',
  'New York Yankees',
  'Oakland Athletics',
  'Philadelphia Phillies',
  'Pittsburgh Pirates',
  'San Diego Padres',
  'San Francisco Giants',
  'Seattle Mariners',
  'St. Louis Cardinals',
  'Tampa Bay Rays',
  'Texas Rangers',
  'Toronto Blue Jays',
  'Washington Nationals',
]

export const FAKE_SETS_DATA = [
  "1950 Jbr 123 1950 B & W Portrait",
  "1950-56 Callahan Hall Of Fame",
  "1950 Jrm 35 Red Hand",
  "1950 Jbr 9D Giant Color Marutoku 1st Prize",
  "1950 Jbr 89C Color With Handwriting Font 1st Prize Premium",
  "1952 Berk Ross",
  "1951 Topps Teams",
  "1950 American Nut & Chocolate",
  "1951 Globe Printing San Jose Red Sox",
  "1950 Connie Mack Book Promo",
  "1953 Canadian Exhibits",
  "1951 Wheaties Premium Photos",
  "1950 Hage's Dairy",
  "1952 Jd Mccarthy Postcards",
  "1950 Remar Bread",
  "1952 Globe Printing Dallas Eagles",
  "1952 Mother's Cookies",
  "1951 St. Louis Cardinals Cardinals Introduce Postcards",
  "1950-51 Jga 4 Yakyu Shonen Color Game Set",
  "2019 Epoch Tokyo Yakult Swallows Stars & Legends",
  "2019 Epoch Yokohama Dena Baystars Rookies & Stars",
  "2019 Epoch Saitama Seibu Lions Rookies & Stars",
  "2019 Yokohama Dena Baystars",
  "2019 Choice Daytona Tortugas",
  "2019 Choice Carolina League All-Stars",
  "2019 Choice Delmarva Shorebirds",
  "2019 Choice Beloit Snappers",
  "2019 Bankri/Weei Pawtucket Red Sox World Series Champions Boston Red Sox",
  "2019 Choice Burlington Royals",
  "2011 Topps - Blue Diamond",
  "2011 Topps - Canary Diamond Anniversary",
  "2011 Topps - Cmg Reprints",
  "2011 Topps - Diamond Anniversary",
  "2011 Topps - Cognac Diamond Anniversary",
  "2011 Topps - Diamond Anniversary Authentic Diamonds",
  "2011 Topps - Factory Set All-Star Edition",
  "2011 Topps - Factory Set Holiday Bonus",
  "2011 Topps - Gold",
  "2011 Topps - Foilboard",
  "2011 Topps - Factory Set Limited Edition",
  "2011 Topps - History Of Topps",
  "2011 Topps - Hope Diamond Anniversary",
  "2011 Topps - Mickey Mantle Gold",
  "2011 Topps - Printing Plates Black",
  "2011 Topps - Prime 9 Player Of The Week Refractors",
  "2011 Topps - Manufactured Glove Leather Nameplates Black",
  "1994 Bowman",
  "1994 Bowman's Best",
  "1995 Bowman's Best",
  "1995 Jsw All-Stars '52 Bowman",
  "1995 Jsw All-Stars '50 Bowman",
  "1995 Jsw All-Stars '53 Bowman Black Box",
  "1995 Jsw All-Stars '48 Bowman B&W",
  "1995 Jsw All-Stars '51 Bowman",
  "1995 Jsw All-Stars '53 Bowman Script",
  "1996 Bowman",
  "1996 Bowman's Best",
  "1997 Bowman's Best",
  "1997 Bowman",
  "1997 Bowman Chrome",
  "1998 Bowman",
  "1998 Bowman Chrome",
  "1998 Bowman's Best",
  "1999 Bowman",
  "1999 Bowman Chrome",
  "1999 Bowman's Best",
  "1999 Sport Shots Williamsport Crosscutters Legends Of Bowman"
]

export const SEARCH_FILTERS = [ 'Card Database', 'Batches' ] //  'Inventory' will be added

export const CANCEL_SUBSCRIPTION_FEEDBACK = [
  { label: 'It’s too expensive', field: 'too_expensive' },
  { label: 'Some features are missing', field: 'missing_features' },
  { label: 'I’m switching to a different service', field: 'switched_service' },
  { label: 'I don’t use the service enough', field: 'unused' },
  { label: 'Customer service was less than expected', field: 'customer_service' },
  { label: 'Ease of use was less than expected', field: 'too_complex' },
  { label: 'Quality was less than expected', field: 'low_quality' },
  { label: 'Other reason', field: 'other' },
]