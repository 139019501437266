<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { useBatchStore } from "@/stores/BatchStore";
import { icons } from "@/helpers/icons";

const batchStore = useBatchStore();

const props = defineProps({
  isBatchDetailsFlow: Boolean,
});

const emit = defineEmits([
  "addToCurrentBatch",
  "createNewBatch",
  "cdpBatchesSearch",
  "closeSidebar",
]);
</script>

<template>
  <Menu as="div" class="relative" v-slot="{ open, close }">
    <MenuButton
      class="flex items-center gap-x-1.5 py-1.5 pl-3 pr-4 flex-grow border border-blue-600 rounded-full bg-gray-800/10 text-white"
      @click="$emit('closeSidebar')"
      :disabled="!batchStore.config?.hasActiveSubscription"
    >
      <span v-html="icons.card_add" />
      <h4 class="text-sm font-semibold whitespace-nowrap">Add Cards</h4>
    </MenuButton>
    <Transition name="menu-scale">
      <MenuItems
        class="z-10 bg-white rounded-md shadow-sm border w-max absolute top-full left-0 translate-y-1 text-sm py-2 flex flex-col"
        as="ul"
      >
        <MenuItem
          as="button"
          class="flex items-center w-full text-left gap-x-3 px-4 py-2 transition duration-300 hover:bg-gray-100 order-1"
          v-if="isBatchDetailsFlow"
          @click="$emit('addToCurrentBatch')"
        >
          <span v-html="icons.existing_batch" />
          <span class="flex-grow">Add to <b>this</b> Batch</span>
        </MenuItem>
        <MenuItem as="button" class="order-3">
          <span
            @click="[$emit('createNewBatch'), close()]"
            class="flex items-center w-full text-left gap-x-3 px-4 py-2 transition duration-300 hover:bg-gray-100"
          >
            <span v-html="icons.blank_batch" />
            <span class="flex-grow">Create a new batch</span>
          </span>
        </MenuItem>
        <MenuItem
          as="button"
          class="flex items-center w-full text-left gap-x-3 px-4 py-2 transition duration-300 hover:bg-gray-100 order-4"
          @click="$emit('cdpBatchesSearch')"
        >
          <span v-html="icons.existing_batch" />
          <span class="flex-grow">
            {{ batchStore.selectedBatch ? "Add to an existing batch" : "Add to a batch" }}
          </span>
        </MenuItem>
      </MenuItems>
    </Transition>
  </Menu>
</template>
